import { StyledHome } from "./Home.styled";
import avatar from "../../images/me2.jpg";
import techie from "../../images/tech.gif";
import { Link } from "react-router-dom";
import Card from "../../utils/Card";
import useFetch from "react-fetch-hook";
import About from "../About/About";
import Contact from "../Contact/Contact";

const Home = () => {
  const { isLoading, error, data } = useFetch(
    "https://api.github.com/users/s-kngstn/repos"
  );
  if (isLoading) return "Loading...";
  if (error) return "Error!";

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const randomProjectList = shuffle(data);

  return (
    <StyledHome>
      <section className="front-page--about">
        <img className="avatar" alt="avatar" src={avatar}></img>
        <div className="about-preview">
          <h1 className="greeting">Hello 👋</h1>
          <br />
          <About />
        </div>
      </section>
      <section className="front-page--projects">
        <h2 className="section-title">
          <i class="icon-link fab fa-github fa-1x" aria-hidden="true"></i>{" "}
          GITHUB
        </h2>
        <br />
        <section className="cards-list">
          <Card
            name={randomProjectList[2].name}
            stars={randomProjectList[2].stargazers_count}
            description={randomProjectList[2].description}
            html_url={randomProjectList[2].html_url}
          />
          <Card
            name={randomProjectList[0].name}
            stars={randomProjectList[0].stargazers_count}
            description={randomProjectList[0].description}
            html_url={randomProjectList[0].html_url}
          />
          <Card
            name={randomProjectList[1].name}
            stars={randomProjectList[1].stargazers_count}
            description={randomProjectList[1].description}
            html_url={randomProjectList[1].html_url}
          />
        </section>
        <Link class="project-link" to="/projects">
          See more of my projects + work →
        </Link>
        <br />
        <br />
      </section>
      <section className="front-page--technologies">
        <img alt="techie" className="techie" src={techie}></img>
        <div>
          <h2 className="section-title right">TECHNOLOGIES</h2>
          <h3 className="tools">LANGUAGES + WEB</h3>
          <p className="tool-details">
            TypeScript, Javascript ES6/Node, Python <br />
            HTML5, CSS3 [BEM Methodology]/SASS, AJAX/JSON, REST
          </p>
          <h3 className="tools">LIBRARIES + FRAMEWORKS</h3>
          <p className="tool-details">
            Next, React [Hooks, Class Components, Lifecycle Methods], ChakraUI,
            Express, Passport, MongoDB, Mongoose
          </p>
          <h3 className="tools">DEVELOPMENT TOOLS</h3>
          <p className="tool-details">
            Git, Bash/Zsh, Docker, Vim, VSCode, Postman, Insomnia, Selenium,
            Heroku, Unix/Linux
          </p>
        </div>
      </section>
      <Contact />
    </StyledHome>
  );
};

export default Home;
