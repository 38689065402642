import React from "react";
import construction from "../../images/under-construction.gif";
import { StyledSectionETC } from "./EtcSections.styled";

const Linux = () => {
  return (
    <StyledSectionETC>
      <React.Fragment>
        <h1>LINUX</h1>
        <p>CURRENTLY IN PROGRESS.. COME BACK SOON</p>
        <img className="img--bw" src={construction} alt="underconstruction" />
      </React.Fragment>
    </StyledSectionETC>
  );
};

export default Linux;
