import styled from "styled-components";

export const StyledSectionETC = styled.div`
  ul {
    margin-top: 1rem;
  }
  li {
    margin-left: 30px;
  }

  h1 {
    font-size: 2.5rem;
    text-transform: uppercase;
  }

  .subtitles--h3 {
    margin-top: 1rem;
  }

  .links--sections {
    margin: 1rem 0 1rem;
  }

  section:last-of-type {
    margin-bottom: 2rem;
  }

  .img--bw {
    filter: grayscale(100%);
    border-radius: 2px;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
      rgb(0 0 0 / 30%) 0px 8px 16px -8px;
    margin: 1rem 0 1rem 0;
  }

  .gif {
    height: 400px;
    width: 500px;
    object-fit: cover;
    filter: grayscale(100%);
    border-radius: 2px;
    box-shadow: rgb(50 50 93 / 25%) 0px 13px 27px -5px,
      rgb(0 0 0 / 30%) 0px 8px 16px -8px;
  }

  .web--link {
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    opacity: 0.6;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }

  @media screen and (max-width: 900px) {
    .gif {
      width: 100%;
    }

    .books--header-img {
      width: 100%;
    }
  }
`;
