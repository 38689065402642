import styled from "styled-components";

export const StyledHome = styled.div`
  .front-page--about {
    display: flex;
    flex-direction: row-reverse;
  }

  section {
    padding-bottom: 1rem;
  }

  .about-preview {
    padding-right: 3rem;
  }

  .greeting {
    font-size: 3rem;
  }

  .section-title {
    font-size: 2.5rem;
  }

  .section-title.right {
    text-align: right;
    margin-bottom: 2rem;
  }

  .front-page--technologies {
    display: flex;
    align-items: flex-start;
    margin-top: 2rem;
  }

  .front-page--projects {
    margin-top: 2rem;
  }

  .tools {
    font-size: 1.5rem;
    text-decoration: underline;
    margin-bottom: 1rem;
    text-align: right;
  }

  .tool-details {
    margin-bottom: 1rem;
    text-align: right;
  }

  .techie {
    border-radius: 2px;
    width: 80%;
    margin-right: 3rem;
    height: 350px;
    object-fit: cover;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    // filter: grayscale(100%);
  }

  .avatar {
    max-width: 350px;
    height: 350px;
    object-fit: cover;
    border-radius: 2px;
    margin-bottom: 2rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.text};
    opacity: 1;
  }

  a:hover {
    opacity: 0.6;
  }

  .project-link {
    opacity: 0.6;
  }

  .project-link:hover {
    opacity: 1;
  }

  .cards-list {
    display: flex;
    gap: 0 20px;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 900px) {
    .front-page--about,
    .front-page--technologies {
      flex-direction: column;
    }
    .techie {
      margin-bottom: 1rem;
      width: 90%;
    }

    .section-title.right {
      text-align: left;
    }
    .tools {
      text-align: left;
    }

    .tool-details {
      text-align: left;
    }

    .about-preview {
      padding-right: 0;
    }
  }
`;
