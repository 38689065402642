// import { Link } from "react-router-dom";
import { StyledDesktopMenu } from "./DesktopMenu.styled.js";
import { NavLink } from "react-router-dom";
import CV from "../../components/images/documents/sam-kingston-resume-07-2022.pdf";

const Menu = () => {
  return (
    <StyledDesktopMenu>
      <ul className="menu">
        <li className="menu-item">
          <NavLink to="/">About</NavLink>
        </li>
        <li className="menu-item">
          <NavLink to="/projects">Projects</NavLink>
        </li>
        <li className="menu-item">
          <a href={CV} target="_blank" rel="noreferrer">
            CV
          </a>
        </li>
        <li className="menu-item">
          <NavLink to="/etc">Etc</NavLink>
        </li>
      </ul>
    </StyledDesktopMenu>
  );
};

export default Menu;
