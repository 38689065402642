import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { StyledLargeCard } from "./LargeCard.styled";
import { titleFormatter } from "./titleFormatter";

const LargeLinkCard = (props) => {
  return (
    <StyledLargeCard>
        <Link  
          className="link"
          to={props.linkto}
        >
          <h2 className="title">{titleFormatter(props.title)}</h2>
          <p className="subtitle">{props.subtitle}</p>
          <h4 className="stack">{props.stack}</h4>
          <img className="img" src={props.img} alt="App Preview"></img>
          <p className="description">
            {props.description}
          </p>
          <p className="publish-date"><em>Published: {props.published}</em></p>
        </Link>
    </StyledLargeCard>
  );
};

export default LargeLinkCard;