export const lightTheme = {
  body: "#fbfbfd",
  text: "#222",
  hover: "#ededed",
  toggleBorder: "#333",
  gradient: "#ededed",
  mobile: "600px",
};

export const darkTheme = {
  body: "#1C1818",
  text: "#d1fae4",
  // text: '#72DC8B',
  hover: "#333",
  toggleBorder: "#d1fae4",
  gradient: "#222",
  mobile: "600px",
};

// #FBFBFD;
// #d1fae4
