import styled from "styled-components";

export const StyledContact = styled.div`
  section {
    padding-bottom: 1rem;
  }
  .contact {
    font-size: 1.5rem;
    margin: 2rem 0 1rem 0;
    display: block;
  }

  .contact-details {
    margin-bottom: 1rem;
  }

  .section-title {
    font-size: 2.5rem;
  }


  a {
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.text};
    opacity: 0.6;
  }

  a:hover {
    opacity: 1;
  }

  @media screen and (max-width: 900px) {
    .front-page--contact {
      flex-direction: column;
    }
  }
`;
