import styled from "styled-components";

export const StyledBurger = styled.button`
  position: absolute;
  top: 1.4rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    height: 0.2rem;
    background: ${({ theme }) => theme.text};
    background: ${({ theme, open }) => (open ? theme.body : theme.text)};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      width: ${({ open }) => (open ? "2rem" : "2.5rem")};
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      width: 2rem;
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      width: 2rem;
      opacity: ${({ open }) => (open ? "1" : "0")};
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }

  @media screen and (min-width: 600px) {
    display: none;

    div {
      display: none;
    }
  }
`;
