import styled from "styled-components";

export const StyledOnePost = styled.div`
  article {
    position: relative;
    margin: 1rem 0 2rem 0;
  }

  .article--banner {
    width: 800px;
    height: 200px;
    border-radius: 2px;
    background-size: 800px auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  .article--content {
    margin: 2rem 0;
  }

  .article--hero-wrapper {
    position: relative;
  }
  .article--title {
    font-size: 2.5rem;
    line-height: 1;
  }

  .article--date {
    font-family:"Share Tech Mono", monospace; 
  }
  .article--subtitle, .article--date {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
  }
  
  .article--hero-content {
    position: absolute;
    color: #d1fae4;
    top: 0;
    left: 0;
    padding: 10px;
  }
  
  
  .article--img {
    width: 800px;
    height: 400px
    object-fit: cover;
    border-radius: 2px;
    padding: 2rem;
  }

  .article--link {
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    font-weight: bold;
  }

  h2, h3 {
    margin-bottom: 10px;
  }

  h2 {
    text-transform: uppercase;
  }

  p {
    max-width: 800px;
    margin-bottom: 15px;
  }

  .article--redtext {
    color: #E25822;
  }

  @media screen and (max-width: 900px) {
    .article--banner {
      width: 100%;
    }

    .article--img {
      width: 100%;
    }

  }

  ul {
    margin-bottom: 15px;
  }
  li {
    margin-left: 30px
  }


`
