import React, { useState } from "react";
import { StyledAbout } from "./About.styled";
import { Link } from "react-router-dom";

function About() {
  const [isShown, setIsShown] = useState(() => "false");

  const handleToggle = () => {
    setIsShown((isShown) => !isShown);
  };

  const extraPara = (
    <p>
      When I'm not building + breaking things on the web or tinkering with
      technologies, I travel to new places, read, build PCs, game on PCs, cook
      and love to learn new things. I have always been fascinated with
      technology. Learning to program came with the benefit of allowing me to be
      creative, while forcing me to build a tolerance and appreciation for
      critical thinking and problem solving skills. I believe being able to
      break down any problem and solve it is one of the most important skills we
      can develop for ourselves. I am currently building a lot of small apps,
      working on learning a front end framework and looking at building modular
      and reusable components that improve the efficiency of old and future code
      bases. My goal is to build something that can make a positive impact in
      others lives.
    </p>
  );

  return (
    <StyledAbout>
      <React.Fragment>
        <p>
          I'm Sam, a developer currently living in London 🇬🇧. I have built
          samkingston.xyz as a space to document my work and open source
          projects. It also serves as a portal to a digital garden,{" "}
          <Link class="etc" to="/etc">
            [etc]
          </Link>
          . It's somewhere between notebook, wiki + blog. A place to document my
          personal interests, travels, thoughts, notes + opinions as they
          evolve. Much like all software, this website has no final or achetypal
          version, only its current state.
        </p>
        <br />
        <p>{!isShown && extraPara}</p>
        <br />
        <button onClick={handleToggle}>
          {isShown ? "Tell me more →" : "Tell me less →"}
        </button>
      </React.Fragment>
    </StyledAbout>
  );
}

export default About;
