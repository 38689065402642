import { useEffect, useState } from "react";
import articleData from "../articleData";
import DonateTag from "./DonateTag";
import { StyledOnePost } from "./OnePost.styled";

const OnePost = () => {
  const [article, setArticle] = useState("");
  useEffect(() => {
    var url = `${document.URL}`;
    const filter = articleData.filter((article) => article.articleUrl === url);
    const articleUrl = filter[0].fullArticle;
    return setArticle(articleUrl);
  }, []);
  return (
    <StyledOnePost>
      {article}
      <DonateTag />
    </StyledOnePost>
  );
};

export default OnePost;
