import { Link } from "react-router-dom";
import avatar from "../images/logo-img.jpg";
// import "./css/logo.css";
import { StyledLogo } from "./Logo.styled";

const Logo = () => {
  return (
    <StyledLogo>
      <Link to="/">
        <div className="logo logo-text">
          <img className="logo-img" alt="avatar" src={avatar}></img>
          Sam Kingston
        </div>
      </Link>
    </StyledLogo>
  );
};

export default Logo;
