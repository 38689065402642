import styled from "styled-components";

export const StyledDonateTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 2rem 0;

  a {

    text-decoration: none;
    color: ${({ theme }) => theme.text};
    font-weight: bold;
  }
`