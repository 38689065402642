import { StyledFooter } from "./Footer.styled";
import CV from "../../components/images/documents/sam-kingston-resume-07-2022.pdf";

const Footer = () => {
  return (
    <StyledFooter>
      <strong>
        <a className="copy email" href="/">
          <p>2022 &copy; samkingston.xyz</p>
        </a>
      </strong>
      <ul>
        <li>
          <a href="https://github.com/s-kngstn">
            <i class="fa fa-github fa-lg" aria-hidden="false"></i>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/s-kngstn">
            <i class="fa fa-linkedin fa-lg" aria-hidden="false"></i>
          </a>
        </li>
        <li>
          <a href="https://stackoverflow.com/users/14645279/sam-kingston">
            <i class="fa fa-stack-overflow fa-lg" aria-hidden="false"></i>
          </a>
        </li>
        <li>
          <a href={CV}>
            <i class="fa fa-file fa-md" arria-hidden="false"></i>
          </a>
        </li>
      </ul>
    </StyledFooter>
  );
};

export default Footer;
