import { StyledCard } from "./Card.styled";
import { Link } from "react-router-dom";

const LinkCard = (props) => {
  return (
    <StyledCard>
      <Link to={props.linkto}>
        <div className="card">
          {props.children}
        </div>
      </Link>
    </StyledCard>
  );
};

export default LinkCard;