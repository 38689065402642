import { StyledDonateTag } from "./DonateTag.styled";

const DonateTag = () => {
  return (
    <StyledDonateTag>
      <a href="https://www.buymeacoffee.com/skingston">
        (☕ || 🍺) ? 💰 : NULL
      </a>
    </StyledDonateTag>
  );
};

export default DonateTag;
