import React from "react";
import { StyledMenuBurger } from "./BurgerMenu.styled";
import { bool } from "prop-types";
import CV from "../../components/images/documents/sam-kingston-resume-07-2022.pdf";
// import { Link } from "react-router-dom";
// import { NavLink } from "react-router-dom";

const BurgerMenu = ({ open }) => {
  return (
    <StyledMenuBurger open={open}>
      <a href="/">About</a>
      <a href="/projects">Projects</a>
      <a href={CV} target="_blank" rel="noreferrer">
        CV
      </a>
      <a href="/etc">Etc</a>
    </StyledMenuBurger>
  );
};

BurgerMenu.propTypes = {
  open: bool.isRequired,
};

export default BurgerMenu;
