import { StyledGrid } from "../../utils/Grid.styled";
import Card from "../../utils/Card";
import useFetch from "react-fetch-hook";
import ProjectCard from "./ProjectCard";
import projectData from "./projectData.js";
import Contact from "../Contact/Contact";

const Projects = () => {
  const { isLoading, error, data } = useFetch(
    "https://api.github.com/users/s-kngstn/repos"
  );

  if (isLoading) return "Loading...";
  if (error) return "Error!";

  const cards = data.map((item, index) => {
    return (
      <Card
        key={item.id}
        name={item.name}
        stars={item.stargazers_count}
        description={item.description}
        html_url={item.html_url}
      />
    );
  });

  const projects = projectData.map((item) => {
    return (
      <ProjectCard
        key={item.id}
        title={item.title}
        subtitle={item.subtitle}
        stack={item.stack}
        img={item.img.default}
        weblink={item.weblink}
        description={item.description}
      />
    );
  });

  return (
    <StyledGrid>
      <div className="container">
        <section className="item-a project-list">
          <h2 className="projects-header projects">PROJECTS</h2>
          {projects}
        </section>
        <section className="item-b card-list">
          <h2 className="github">GITHUB</h2>
          {cards}
        </section>
      </div>
      <Contact />
    </StyledGrid>
  );
};

export default Projects;
