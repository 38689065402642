import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { ThemeProvider } from "styled-components";
import { useDarkMode } from "./components/theme/useDarkMode";
import { lightTheme, darkTheme } from "./components/theme/theme";
import { GlobalStyles } from "./globalcss";

import Logo from "../src/components/Logo/Logo";
import DesktopMenu from "../src/components/DesktopMenu/DesktopMenu";
import Toggle from "../src/components/theme/Toggle";
import Burger from "../src/components/Burger/Burger";
import BurgerMenu from "../src/components/BurgerMenu/BurgerMenu";
import Home from "./components/Layout/Home/Home";
import Projects from "./components/Layout/Projects/Projects";
import Footer from "./components/Footer/Footer";
import Linux from "./components/Layout/etc/Linux";
import EtcPage from "./components/Layout/etc/Etc";
import Books from "./components/Layout/etc/Books";
import Travel from "./components/Layout/etc/Travel";
import Recipes from "./components/Layout/etc/Recipes";
import Www from "./components/Layout/etc/Www";
import OnePost from "./components/utils/OnePost";

function App() {
  const [theme, toggleTheme, componentMounted] = useDarkMode();
  const [open, setOpen] = useState(() => false);

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  if (!componentMounted) return <div />;
  // const nums = [0, 1, 2]
  return (
    <ThemeProvider theme={themeMode}>
      <Router>
        <GlobalStyles />
        <header className="sticky-menu">
          <div className="header">
            <Logo />
            <nav className="navigation">
              <DesktopMenu />
              <Toggle theme={theme} toggleTheme={toggleTheme} />
            </nav>
            <Burger open={open} setOpen={setOpen} />
            <BurgerMenu open={open} setOpen={setOpen} />
          </div>
        </header>
        <main>
          <Route exact path="/" component={Home} />
          <Switch>
            <Route path="/projects" component={Projects} />
            <Route exact path="/etc" component={EtcPage} />
            <Route path="/etc/linux" component={Linux} />
            <Route path="/etc/books" component={Books} />
            <Route path="/etc/travel" component={Travel} />
            <Route path="/etc/www" component={Www} />
            <Route path="/etc/recipes" component={Recipes} />
            <Route exact path={`/etc/:id`} component={OnePost} />
            <Redirect to="/" />
          </Switch>
        </main>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
