import { useState } from "react";
import { StyledContact } from "./Contact.styled";

const Contact = () => {
  const [mouseOver, setMouseOver] = useState(() => "false");
  const handleMouseOver = () => {
    setMouseOver((mouseOver) => !mouseOver);
  };
  return (
    <StyledContact>
      <section onMouseEnter={handleMouseOver} className="front-page--contact">
        <h2 className="section-title">CONTACT</h2>
        <h3 className="contact">
          LETS {mouseOver ? "BUILD" : "BREAK"} SOMETHING.
        </h3>
        <p className="contact-details">
          Please send me an email if you are looking for an engineer on your
          next project, want to collaborate, or have any questions.
        </p>
        <a className="contact-btn" href="mailto:sk@samkingston.xyz">
          Get in touch →
        </a>
      </section>
    </StyledContact>
  );
};

export default Contact;
