import styled from "styled-components";

export const StyledCard = styled.div`
  div {
    padding: 1rem;
  }

  .card {
    padding: 1rem 0;
    width: 100%;
    border-bottom: #333 1px solid;
    font-family: "Lato", sans-serif;
  }

  .card:hover {
    background: ${({ theme }) => theme.hover};
  }

  .star-count {
    padding: 8px 0 3px 0;
  }

  .star {
    height: 50px;
    width: auto;
  }

  @media screen and (min-width: 1090px) {
    .card {
      width: 360px;
    }
  }
`;
