import reacthook from "../images/reacthook.jpeg";
import codesnippet1 from "../images/codesnippets/codesnippet1.png";
import codesnippet2 from "../images/codesnippets/codesnippet2.png";
import codesnippet3 from "../images/codesnippets/codesnippet3.png";
import codesnippet4 from "../images/codesnippets/codesnippet4.png";
import codesnippet5 from "../images/codesnippets/codesnippet5.png";
import codesnippet6 from "../images/codesnippets/codesnippet6.png";
import codesnippet7 from "../images/codesnippets/codesnippet7.png";
import codesnippet8 from "../images/codesnippets/codesnippet8.png";
import codesnippet9 from "../images/codesnippets/codesnippet9.png";

const theUseStateHook = {
  id: 1,
  title: "the-usestate-hook",
  subtitle: "A detailed write-up on React's use state function",
  stack: "Development | Front-end | Javascript",
  published: "2021·12",
  img: require("../images/reacthook.jpeg"),
  linkto: "etc/",
  articleUrl: "https://samkingston.xyz/etc/the-usestate-hook",
  description:
    "Hooks are special functions that let you 'hook into' React features, let's take a deep dive into why and how to use it..",
  fullArticle: (
    <article>
      <div className="article--hero-wrapper">
        <div
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${reacthook})`,
          }}
          className="article--banner"
        ></div>
        <div className="article--hero-content">
          <h1 className="article--title">THE USESTATE HOOK</h1>
          <p className="article--subtitle">
            A detailed write-up on Reacts first hook
          </p>
          <p className="article--date">2021·12</p>
        </div>
      </div>
      <div className="article--content">
        <p>
          <em>
            Disclaimer: These technical posts are written with the intention of
            solidifying my knowledge in a particular subject. If you want to
            fully understand React hooks I recommend hitting the source first
            and{" "}
            <a
              className="article--link"
              href="https://reactjs.org/docs/hooks-intro.html"
            >
              reading the official documentation.
            </a>
            But, if this article helps anyone else understand any of the
            concepts I have written about, then I am glad my writing has helped
            you.
          </em>
        </p>
        <h3>Props vs. State</h3>
        <p>
          “Props” refers to the properties <u>being passed into a component</u>{" "}
          in order for it to work correctly, similar to how a function receives
          parameters: <em>from above</em>. A component receiving props is not
          allowed to modify these props. (i.e they are immutable [unchanging])
        </p>
        <p>
          Incoming props should be immutable and never change manually, or more
          specifically, props should never change within the body of the
          component itself.
        </p>
        <p>
          “State” refers to values that are <em>managed by the component</em>,
          similar to variables declared <em>inside</em> a function.
        </p>
        <p className="article--redtext">
          Any time you have changing values that should be saved/displayed,
          you’ll likely be using state.
        </p>
        <p>
          In general, State simply means <em>the way things currently are.</em>
        </p>
        <p>
          In React, State refers to values that a component can maintain between
          render cycles.
        </p>
        <h3>useState() in detail</h3>
        <p>
          useState is a JavaScript array that takes in two parameters with the
          second one being a higher order function.
        </p>
        <img className="article--img" src={codesnippet1} alt="code snippet" />
        <p>
          Lets take this a step further and use array destructuring on the
          useState hook:
        </p>
        <img className="article--img" src={codesnippet2} alt="code snippet" />
        <p>
          For state to change, you need a useState hook with the default state
          and the new state (a change in the default state). You also need a
          handler function to hold your change state inside:
        </p>
        <img className="article--img" src={codesnippet3} alt="code snippet" />
        <h3>Best Practises</h3>
        <p>
          Because you never want to modify the original state, doing something
          like the example below would be considered bad practise because we are
          directly mutating the original state:
        </p>
        <img className="article--img" src={codesnippet4} alt="code snippet" />
        <p>
          Instead, if we ever need to use our old state value to determine a new
          value of state, we can provide a callback function inside our setValue
          function.
        </p>
        <p>
          When we provide a callback function inside our setValue function, the
          callback function needs to return the new value we want our state to
          be.
        </p>
        <p>
          So anytime we do this, react passes the old state value as a parameter
          of the callback function. What we can then do is take the old value
          parameter and use it as our reference to what the value was at the
          time this function was called:
        </p>
        <img className="article--img" src={codesnippet5} alt="code snippet" />
        <h3>Recap:</h3>
        <p>
          If you ever need the old value of state to help you determine the new
          value of state, you should pass a callback function to your state
          setter function (in this case the setValue function) instead of using
          the state directly.
        </p>
        <p>
          This callback function will receive the old value of state as it's
          parameters, which you can then use to determine the new value of
          state.
        </p>
        <p>
          Whenever passing values into your state setter function, you have two
          options:
        </p>
        <p>
          If you <u>DON'T</u> need the previous value of state to determine what
          the new value should be then you can input the values directly into
          the state setter functions parameter:
        </p>
        <img className="article--img" src={codesnippet6} alt="code snippet" />
        <p>
          If you <u>DO</u> need the previous values to determine the new value
          then you should use a callback function inside the state setter's
          parameter:
        </p>
        <img className="article--img" src={codesnippet7} alt="code snippet" />
        <h3 className="article--redtext">NEVER DIRECTLY MODIFY STATE</h3>
        <h3>Complex State - Arrays (and the array spread operator)</h3>
        <p>Here is an example of using Arrays with State:</p>
        <img className="article--img" src={codesnippet8} alt="code snippet" />
        <h3>
          Complex State - Updating State Objects (and the obj spread operator)
        </h3>
        <p>Here is an example of using Objects with State:</p>
        <img className="article--img" src={codesnippet9} alt="code snippet" />
      </div>
    </article>
  ),
};

export default theUseStateHook;
