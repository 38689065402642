import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ::selection {
    background: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.body};
  }

  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: 'Lato', sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    transition: all 0.3s;
  }

  h1, h2, h3, h4, h5, h6, a {
    font-family:"Share Tech Mono", monospace; 
  }

  p {
    letter-spacing: 1.1px;
    line-height: 1.5;
  }
  
  main {
    padding: 6rem 1.5rem 0 1.5rem;
    margin: 0 auto;
    max-width: 1200px;
  }

  .sticky-menu {
    position: fixed;
    width: 100%;
    border-bottom: 1px #333 solid;
    z-index: 99999999;
    backdrop-filter: blur(6px);
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem 1rem 1.5rem;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  
  
  @media screen and (max-width: 600px) {
    .navigation {
      display: none;
    }
  }  

  `;
