import book from "../../images/books.jpeg";
import stimpybook from "../../images/stimpy-book.gif";
import { StyledSectionETC } from "./EtcSections.styled";

const Books = () => {
  return (
    <StyledSectionETC>
      <h1>Books</h1>
      <p>A curated reading list (or at least what I can remember of it)</p>
      <img className="img--bw books--header-img" src={book} alt="Book Shelf" />
      <section>
        <h3 className="subtitles--h3">Currently reading...</h3>
        <ul>
          <li>
            <strong>Permanent Record</strong> Edward Snowden
          </li>
          <li>
            <strong>
              Code: The Hidden Language of Computer Hardware and Software
            </strong>{" "}
            Charles Petzold
          </li>
          <li>
            <strong>
              The Great War for Civilisation: The Conquest of The Middle East
            </strong>{" "}
            Robert Fisk
          </li>
        </ul>
      </section>
      <section>
        <h3 className="subtitles--h3">2022</h3>
        <ul>
          <li>
            <strong>The Unwritten Laws of Engineering: Second Edition</strong> W.J King, James G. Skakoon
          </li>
        </ul>
      </section>
      <section>
        <h3 className="subtitles--h3">2021</h3>
        <ul>
          <li>
            <strong>Entangled Life</strong> Merlin Sheldrake
          </li>
          <li>
            <strong>How to Give Up Plastic</strong> Will McCallum
          </li>
          <li>
            <strong>The Pragmatic Programmer</strong> Andrew Hunt, David Thomas
          </li>
          <li>
            <strong>The Hidden Life of Trees</strong> Peter Wohlleben
          </li>
          <li>
            <strong>Ghost in The Wires</strong> Kevin Mitnick
          </li>
        </ul>
      </section>
      <section>
        <h3 className="subtitles--h3">2020</h3>
        <ul>
          <li>
            <strong>The Wind-Up Bird Chronicles</strong> Haruki Murukami
          </li>
          <li>
            <strong>Blood Meridian</strong> Cormac McCarthy
          </li>
          <li>
            <strong>Dark Matter</strong> Blake Crouch
          </li>
        </ul>
      </section>
      <img className="img--bw" src={stimpybook} alt="Book Shelf" />
      <section>
        <h3 className="subtitles--h3">2019</h3>
        <ul>
          <li>
            <strong>The Myth of Sisyphus</strong> Albert Camus
          </li>
          <li>
            <strong>Undisputed Truth</strong> Mike Tyson
          </li>
          <li>
            <strong>To Be a Machine</strong> Mark O'Connell
          </li>
          <li>
            <strong>Mistborn</strong> Brandon Sanderson
          </li>
        </ul>
      </section>

      <section>
        <h3 className="subtitles--h3">2018</h3>
        <ul>
          <li>
            <strong>Kitchen Confidential</strong> Anthony Bourdain
          </li>
          <li>
            <strong>Snow Crash</strong> Neil Stephenson
          </li>
          <li>
            <strong>Musashi</strong> Eiji Yoshikawa
          </li>
          <li>
            <strong>Hyperion</strong> Dan Simmons
          </li>
        </ul>
      </section>
    </StyledSectionETC>
  );
};

export default Books;
