import styled from "styled-components";

export const StyledDesktopMenu = styled.nav`
  .menu {
    display: flex;
    align-items: center;
    flex-direction: row;
    list-style-type: none;
    font-size: 1.3rem;
    z-index: 10000;
  }

  .menu-item {
    padding: 0 10px;
  }

  .menu-item a {
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    opacity: 0.6;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }

  // .menu-item:last-of-type a {
  //   opacity: 1;
  // }


  /* @media screen and (max-width: 600px) {
  .menu {
    display: none;
  }
} */
`;
