const projectData = [
  {
    id: 1,
    title: "intellimenu.",
    subtitle: "A cutting edge menu filtering system",
    stack: "TypeScript | NextJS | PostGreSQL | Prisma",
    img: require("../../images/intelli-menu.png"),
    weblink: "https://intelli-menu.com",
    description:
      "Intellimenu is a fullstack web app I built to provide restaurant owners with an option to showcase their menu in an intelligent way, by asbtracting menu items away based on any guests allergy or dietary preferences. Chefs/Managers/Restaurant Owners can create an account, log in, CRUD Restaurants, Menus & Menu Items.. and provide a QR code for their guests to scan which opens up an intelligent menu for guests to browse on their mobile phones. The project is deployed and currently has a relational database running on Heroku. I plan to continue growing this project and would next like to offer some data analytics for businesses so they can better prepare menu's that carter to all dietary preferences.",
  },
  {
    id: 2,
    title: "Wu Lorem Ipsum",
    subtitle: "A text generator",
    stack: "Vanilla JS | CSS | HTML",
    img: require("../../images/wuproject.png"),
    weblink: "https://s-kngstn.github.io/wu-loremipsum",
    description:
      "This small web application is a modern take on a Lorem Ipsum Generator. What is Lorem Ipsum? In publishing, web and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. It may be used as a placeholder before a final copy is available. Traditional Lorem ipsum text is derived from sections of Cicero's 'De finibus bonorum et malorum'. Instead, I created a database of classic hip-hop lyrics from one of my favorite rap groups The Wu Tang Clan to use in place of Cicero's latin. If you're interested in reading more into my workflow and taking a look at the code I wrote to make the generator work then please visit the Github link below.",
  },
  {
    id: 3,
    title: "Chat with Kanye",
    subtitle: "A Kanye West chatbot",
    stack: "Vanilla JS | CSS | HTML | REST",
    img: require("../../images/kanye-project-image.png"),
    weblink: "https://s-kngstn.github.io/kanye-chatbot/",
    description:
      "As part of a 24hour hackathon I built this fun conversation generator or sorts...Kanye West interacts and responds to a number to different things, including providing food recipes, telling fortunes, offering up a random quote of his and providing your local time. Chat to Kanye currently uses two public APIs and a handful of string arrays to create a response to your message.",
  },
  {
    id: 4,
    title: "Whats in your fridge?",
    subtitle: "A recipe search engine",
    stack: "Node | Express | EJS | REST",
    img: require("../../images/fridge-app.png"),
    weblink: "https://whats-in-my-fridge-app.herokuapp.com/",
    description:
      "I built this webapp using nodejs with express for the back-end. Plus Embedded Javascript for the front end. Often we dont know what to do with the extra ingredients slowly reaching their expiration date in the fridge or pantry. This app offers up a recipe suggestion using the ingredients you type into the search bar. I orginally built this whole application on the front end and switched to using express when I realised I needed to store my API key securley. A challenge during building this project was that the Spoontacular API doesnt provide cooking instructions for it's recipes in the same object, it requires two seperate API calls. To combat this I used axios and asynchronous programming to search for the same recipe's cooking directions using a unique ID that came with the inital data set. Sometimes, Spoontacular didnt even include cooking instructions. To combat this, I used a conditional to redirect and search for an alternate recipe using the same ingredients.",
  },
  {
    id: 5,
    title: "The Wine List",
    subtitle: "A wine tasting tool",
    stack: "React | Node | Express | MongoDB",
    img: require("../../images/wine-app.png"),
    weblink: "https://fierce-escarpment-67494.herokuapp.com/",
    description:
      "I built this webapp using the MERN stack to help staff at a restaurant learn the wine list. It also doubles down as a filtering tool for guests to pick wine based on their preferences.",
  },
  {
    id: 6,
    title: "Allergy Menu - Intelli Menu Prototype",
    subtitle: "A test prototype for intellimenu",
    stack: "Next | React | TypeScript",
    img: require("../../images/allergy-menu.png"),
    weblink: "https://interactive-dietary-menu.vercel.app/",
    description:
      "A quick mock-up build of a menu filtering system for restaurants to use for helping guests with diet or allergy restrictions plan out their meal choices. This is a smaller prototype for a full stack app I am currently building.",
  },
  {
    id: 7,
    title: "Webdevise",
    subtitle:
      "A freelance website to help small businesses with their web needs",
    stack: "HTML | SASS/CSS | Javascript",
    img: require("../../images/webdevise.png"),
    weblink: "https://s-kngstn.github.io/webdevise/",
    description:
      "This was a business I started shortly before the pandemic, It was designed to help small businesses build professional looking websites and get 24hour customer service support at an affordable monthly price.",
  },
  {
    id: 8,
    title: "Oldroyd Restaurant",
    subtitle: "A small business website",
    stack: "HTML | CSS | Javascript",
    img: require("../../images/oldroyd.png"),
    weblink: "https://s-kngstn.github.io/oldroyd-web/",
    description:
      "Oldroyd was a small local restaurant located in the heart of London, England that I built a website for. This webpage is fully responsive and built with  mobile-first design principles. This was a big step up in design from their previous website. I chose to avoid using frameworks here due to the requirements of the site being so small. So it's all hand-coded HTML, CSS +  Vanilla Javascript.",
  },
];

export default projectData;
