import styled from "styled-components";

export const StyledAbout = styled.div`

  a.etc {
    border-bottom: none;
  }

  button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-family:"Share Tech Mono", monospace;

    opacity: 0.6;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.text}; 

    &:hover {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }
`;