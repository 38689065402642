import styled from "styled-components";

export const StyledLargeCard = styled.div`
    padding: 1rem 0;

  :hover {
    background: ${({ theme }) => theme.hover};
  }

  .title {
    text-transform: uppercase;
    word-spacing: normal;
  }

  .subtitle {
    padding: 5px 0 5px 0;
  }

  .img {
    width: 100%;
    height: 400px;
    border-radius: 2px;
    margin: 10px 0 10px;
    object-fit: cover; 
  }

  p {
    font-family: "Lato", sans-serif;
  }

  .publish-date {
    margin-top: 10px;
  }
`;
