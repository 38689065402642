import Contact from "../Contact/Contact";
import LinkCard from "../../utils/LinkCard";
import { StyledGrid } from "../../utils/Grid.styled";
import LargeLinkCard from "../../utils/LargeCard";
import articleData from "../../articleData";
import { titleFormatter } from "../../utils/titleFormatter";

console.log(articleData);

const EtcPage = () => {
  const articles = articleData.map((item) => {
    return (
      <LargeLinkCard
        key={item.id}
        title={item.title}
        subtitle={item.subtitle}
        stack={item.stack}
        published={item.published}
        img={item.img.default}
        linkto={`${item.linkto}${item.title}`}
        description={item.description}
      />
    );
  });
  return (
    <StyledGrid>
      <div className="container">
        <section className="item-b card-list">
          <h2 className="github">ETC</h2>
          {/* {cards} */}
          <LinkCard key={4} linkto="/etc/books">
            <h2>{titleFormatter("books")}</h2>
            <p>A curated list of books I've been reading..</p>
          </LinkCard>
          <LinkCard key={7} linkto="/etc/www">
            <h2>{titleFormatter("WWW")}</h2>
            <p>Portal to some websites that interest me..</p>
          </LinkCard>
          <LinkCard key={5} linkto="/etc/linux">
            <h2>{titleFormatter("linux")}</h2>
            <p>
              Notes on the open-source operating system, useful commands,
              thoughts..
            </p>
          </LinkCard>
          <LinkCard key={6} linkto="/etc/travel">
            <h2>{titleFormatter("travel")}</h2>
            <p>Memory box for all my wonderful experiences away from home..</p>
          </LinkCard>
          <LinkCard key={8} linkto="/etc/recipes">
            <h2>{titleFormatter("recipes")}</h2>
            <p>Some quick, low-cost and tasty meal ideas..</p>
          </LinkCard>
        </section>
        {/* =================================================================== */}
        <section className="item-a project-list">
          <h2 className="projects-header projects">WRITING</h2>
          {articles}
        </section>
      </div>
      <Contact />
    </StyledGrid>
  );
};

export default EtcPage;
