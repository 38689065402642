import styled from "styled-components";

export const StyledLogo = styled.nav`
  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logo-img {
    height: 2.5rem;
    border-radius: 2px;
    width: auto;
    margin-right: 1rem;
  }

  .logo-text {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.4rem;
    text-decoration: none;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.text};
  }
`;
