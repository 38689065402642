import { StyledLargeCard} from "../../utils/LargeCard.styled";
import PropTypes from 'prop-types'; // ES6


const ProjectCard = (props) => {
  return (
    <StyledLargeCard>
      <div className="card">
        <a  
          target="_blank"
          rel="noreferrer"
          className="project-link"
          href={props.weblink}
        >
          <h2 className="title">{props.title}</h2>
          <p className="subtitle">{props.subtitle}</p>
          <h4 className="stack">{props.stack}</h4>
          <img className="img" src={props.img} alt="App Preview"></img>
          <p className="description">
            {props.description}
          </p>
        </a>
      </div>
    </StyledLargeCard>
  );
};

ProjectCard.propTypes = {
  weblink: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  stack: PropTypes.string,
  img: PropTypes.string,
  description: PropTypes.string,
};


export default ProjectCard;
