import styled from "styled-components";

export const StyledGrid = styled.div`

  .card-list {
    display: flex;
    flex-direction: column;
  }

  .github,
  .projects {
    font-size: 2.5rem;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.text};
  }

  .project-list {
    margin-right: 40px;
  }


  .item-a {
    grid-area: projects;
    margin-bottom: 2rem;
  }

  .item-b {
    grid-area: card-list;
  }

  .container {
    display: grid;
    grid-template-columns: 74% 26%;
    grid-template-rows: auto;
    grid-template-areas:
    "projects card-list";
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 900px) {
    .container {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-template-areas:
      "projects"
      "card-list";
    }

    .project-list {
      margin-right: 0;
    }
  }
`;