import { useState, useEffect } from "react";
import { StyledCard } from "./Card.styled";
import { titleFormatter } from "./titleFormatter";

const Card = (props) => {
  const [star, setStar] = useState("");
  useEffect(() => {
    return props.stars >= 1 ? setStar("⭐") : star;

  }, [props.stars, star]);

  return (
    <StyledCard>
      <a href={props.html_url}>
        <div className="card">
          <h2>{titleFormatter(props.name)}</h2>
          <p className="star-count">
            {star} {props.stars > 0 && props.stars}
          </p>
          <p className="description">{props.description}</p>
        </div>
      </a>
    </StyledCard>
  );
};

export default Card;
