import styled from "styled-components";

export const StyledFooter = styled.footer`
  margin: 0 auto;
  padding: 0.5rem 1.5rem;
  max-width: 1200px;
  border-top: 1px #333 solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.5rem;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.text};

  }

  .copy {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
  }

  li a {
    opacity: 0.6;

    &:hover {
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
  }

  li {
    padding-top: 10px;
    margin-left: 15px;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    line-height: 1.5;
    li {
      margin: 10px;
    }
  }
`;
