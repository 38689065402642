import cube from "../images/cube.jpeg";

const problemSolving = {
  id: 0,
  title: "problem-solving",
  subtitle:
    "A step by step guide that has helped me solve complex coding problems",
  stack: "General Programming",
  published: "2021·11",
  img: require("../images/cube.png"),
  linkto: `/etc/`,
  articleUrl: "https://samkingston.xyz/etc/problem-solving",
  description:
    "Developing a geneuine curiosity and passion for understanding how things actually work and breaking down problems is an important skill to develop.. ",
  fullArticle: (
    // Styling is coming from OnePost.styled.js
    <article>
      <div className="article--hero-wrapper">
        <div
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${cube})`,
          }}
          className="article--banner"
        ></div>
        <div className="article--hero-content">
          <h1 className="article--title">PROBLEM SOLVING</h1>
          <p className="article--subtitle">
            A step by step guide that has helped me solve complex coding
            problems
          </p>
          <p className="article--date">2021·11</p>
        </div>
      </div>
      <div className="article--content">
        <p>
          <em>
            Disclaimer: These technical posts are written with the intention of
            solidifying my knowledge in a particular subject. They have no
            particular structure and are not intended for educational purposes.
            But if this article helps anyone else understand any of the concepts
            I have written about, then I am glad my writing has helped you.
          </em>
        </p>
      </div>
      <h2>Solve any problem</h2>
      <h3>Step 1: Understand the problem</h3>
      <p>
        Make sure you 100% understand the problem you are trying to solve.{" "}
        <u>Ask the right questions</u> to get a clear picture of the problem.
      </p>
      <ul>
        <li>Can I state the problem in my own words?</li>
        <li>
          Dont be afraid to do as much research as you have to.. Google,
          Documentation, YouTube etc.
        </li>
      </ul>
      <h3>Step 2: Divide + Conquer</h3>
      <p>
        Break a big problem down into smaller, more digestable sub-problems.
      </p>
      <ul>
        <li>
          Communicate what you're doing - aka{" "}
          <a className="article--link" href="https://rubberduckdebugging.com/">
            rubber ducking
          </a>
        </li>
        <li>
          Write out the steps you need to take in plain English (or any language
          you're most comfortable with). This can prevent any conceptual issues
          or misunderstandings you may have before you start actual going
          through the process of solving the issue at hand.
        </li>
      </ul>
      <h3>Step 3: Solve + Simplify</h3>
      <ul>
        <li>
          Strip away as much complexity as you can and try to write a simplified
          solution, you can incorporate any complexity back in later.
        </li>
      </ul>
      <h3>Step 4: Look back and refactor</h3>
      <ul>
        <li>Can you find a different approach?</li>
        <li>Is the solution the most efficient one?</li>
        <li>How have other people solved the same problem?</li>
        <li>
          Can the results of this problem be used to solve other problems?
        </li>
      </ul>
    </article>
  ),
};

export default problemSolving;