import styled from "styled-components";

// Toggle.styled.js
export const ToggleContainer = styled.button`
  background: ${({ theme }) => theme.gradient};
  border: 2px solid ${({ theme }) => theme.toggleBorder};
  border-radius: 30px;
  cursor: pointer;
  font-size: 0.5rem;
  overflow: hidden;
  padding: 0.5rem;
  margin: 0 0 0 10px;
  position: relative;
  width: 3.7rem;
  height: 1.8rem;

  svg {
    height: auto;
    width: 1rem;
    transition: all 0.3s linear;
    
    // sun icon
    &:first-child {
      transform: ${({ lightTheme }) => lightTheme ? 'translate(-5px, -3.5px)' : 'translateY(100px)'};
    }
    
    // moon icon
    &:nth-child(2) {
      transform: ${({ lightTheme }) => lightTheme ? 'translateY(-100px)' : 'translate(3px, -4px)'};
    }
  }

  }
`;
