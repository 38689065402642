import { StyledSectionETC } from "./EtcSections.styled";
import websurf from "../../images/websurf.gif";
import React from "react";

const Www = () => {
  return (
    <StyledSectionETC>
      <h1>WWW</h1>
      <p>A list of wonderful places around the internet to visit</p>
      <img className="gif" alt="websurfer" src={websurf}></img>
      <section className="links--sections">
        <h3 className="subtitles--h3">Blog Links</h3>
        <ul>
          <li>
            <a className="web--link" href="https://blog.codinghorror.com/">
              Coding Horror
            </a>
          </li>
          <li>
            <a className="web--link" href="https://vitalik.ca">
              Vitalik Buterin
            </a>
          </li>
          <li>
            <a className="web--link" href="https://danluu.com">
              Dan Luu
            </a>
          </li>
          <li>
            <a className="web--link" href="https://secret.club">
              Secret Club
            </a>
          </li>
          <li>
            <a className="web--link" href="https://100r.co">
              Hundredrabbits
            </a>
          </li>
          <li>
            <a className="web--link" href="https://edwardsnowden.substack.com/">
              Edward Snowden
            </a>
          </li>
          <li>
            <a className="web--link" href="https://wiki.nikitavoloboev.xyz/">
              Nikita Voloboev
            </a>
          </li>
          <li>
            <a className="web--link" href="https://malwaretech.com">
              Malwaretech
            </a>
          </li>
        </ul>
      </section>

      <section className="links--sections">
        <h3 className="subtitles--h3">Technology</h3>
        <ul>
          <li>
            <a className="web--link" href="https://deletefacebook.com/">
              Delete Facebook
            </a>
          </li>
          <li>
            <a className="web--link" href="https://www.eff.org/">
              Electronic Frontier Foundation
            </a>
          </li>
          <li>
            <a className="web--link" href="https://repair.eu/">
              Right to Repair
            </a>
          </li>
          <li>
            <a className="web--link" href="https://deathtobullshit.com/">
              Death to Bullshit
            </a>
          </li>
          <li>
            <a
              className="web--link"
              href="https://wiki.techinc.nl/index.php/Hackers_tribes"
            >
              Hacker Ethics
            </a>
          </li>
          <li>
            <a className="web--link" href="https://brianlovin.com/security">
              Security Checklist
            </a>
          </li>
        </ul>
      </section>

      <section className="links--sections">
        <h3 className="subtitles--h3">General Knowledge</h3>
        <ul>
          <li>
            <a className="web--link" href="https://archive.org/details/books">
              Archive - Free Books
            </a>
          </li>
          <li>
            <a
              className="web--link"
              href="http://catb.org/~esr/faqs/smart-questions"
            >
              How to Ask Questions The Smart Way
            </a>
          </li>
          <li>
            <a
              className="web--link"
              href="https://kk.org/thetechnium/68-bits-of-unsolicited-advice/"
            >
              68 Bits of Unsolicited Advice
            </a>
          </li>
        </ul>
      </section>

      <section className="links--sections">
        <h3 className="subtitles--h3">JavaScript</h3>
        <ul>
          <li>
            <a className="web--link" href="https://www.npmjs.com/">
              NPM
            </a>
          </li>
          <li>
            <a className="web--link" href="https://nodejs.org/api/">
              Node Documentation
            </a>
          </li>
          <li>
            <a className="web--link" href="https://exploringjs.com/impatient-js/toc.html">
              Javascript for impatient programmers
            </a>
          </li>
          <li>
            <a
              className="web--link"
              href="https://www.typescriptlang.org/docs/handbook/"
            >
              TypeScript Documentation
            </a>
          </li>
          <li>
            <a className="web--link" href="https://reactjs.org/">
              React
            </a>
          </li>
          <li>
            <a className="web--link" href="https://expressjs.com/">
              Express
            </a>
          </li>
          <li>
            <a className="web--link" href="https://2020.stateofjs.com/en-US/">
              State of JS - Data on the JavaScript Ecosystem
            </a>
          </li>
          <li>
            <a className="web--link" href="https://socket.io/">
              Socket.io
            </a>
          </li>
          <li>
            <a className="web--link" href="https://prisma.io/">
              Prisma
            </a>
          </li>
          <li>
            <a className="web--link" href="https://nextjs.org/">
              NextJS
            </a>
          </li>
        </ul>
      </section>

      <section className="links--sections">
        <h3 className="subtitles--h3">Cyber Security</h3>
        <ul>
          <li>
            <a
              className="web--link"
              href="https://wiki.owasp.org/index.php/Web_Application_Penetration_Testing"
            >
              OWASP Wiki
            </a>
          </li>
          <li>
            <a className="web--link" href="https://overthewire.org/wargames/">
              Over The Wire - Wargames
            </a>
          </li>
          <li>
            <a className="web--link" href="http://exploit.education/">
              Exploit Education
            </a>
          </li>
          <li>
            <a
              className="web--link"
              href="https://portswigger.net/web-security/all-materials"
            >
              Port Swigger
            </a>
          </li>
          <li>
            <a className="web--link" href="https://hashcat.net/hashcat/">
              Hash Cat - Advanced Password Recovery
            </a>
          </li>
          <li>
            <a
              className="web--link"
              href="https://jhalon.github.io/becoming-a-pentester/"
            >
              Becoming a Pentester
            </a>
          </li>
          <li>
            <a className="web--link" href="https://hackerone.com/hacktivity">
              Hacker One - Hacktivity
            </a>
          </li>
          <li>
            <a class="web--link" href="https://www.professormesser.com/security-plus/sy0-601/sy0-601-video/sy0-601-comptia-security-plus-course/">
              Professor Messer - Cyber Security+
            </a>
          </li>
        </ul>
      </section>
    </StyledSectionETC>
  );
};

export default Www;
