import React from "react";
import { StyledSectionETC } from "./EtcSections.styled";
import construction from "../../images/under-construction.gif"

const Travel = () => {
  return (
    <StyledSectionETC>
      <React.Fragment>
        <h1>Travel</h1>
        <p>CURRENTLY IN PROGRESS.. COME BACK SOON</p>
        <img className="img--bw" src={construction} alt="underconstruction" />
      </React.Fragment>
    </StyledSectionETC>
  )
}

export default Travel